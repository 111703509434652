<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <Create />
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import Create from "./Create.vue";

export default {
  name: "Withdraw",
  data() {
    return { }
  },
  components: {
    Breadcrumb,
    Create,
  },
  computed: {
    breadcrumb_data() {
      return {
        title: this.$t('withdraw.name')
      }
    },
  },
  methods: {},
  created() {},
};
</script>
