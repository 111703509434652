<template>
  <div class="modal fade" :class="{ show: showCreateWalletModal }">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ getTitle }}</h5>
          <button type="button" class="close" @click="closeModal()">
            <i aria-hidden="true" class="ki ki-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <!--Begin::Create Bank Account -->
            <form class="form" v-if="provider === 'bank_transfer'">
              <div class="form-group flex-column">
                <label class="col-form-label">{{ $t('finance.account_name') }}</label>
                <validation-provider
                  rules="required"
                  :name="$t('finance.account_name')"
                  v-slot="{ classes, errors }"
                >
                  <input
                    class="form-control"
                    :class="classes"
                    type="text"
                    v-model="formData.label"
                    :placeholder="$t('finance.account_name')"
                    :disabled="isSubmitting"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group flex-column">
                <label class="col-form-label">{{ $t('finance.currency') }}</label>
                <validation-provider
                  rules="required"
                  :name="$t('finance.currency')"
                  v-slot="{ classes, errors }"
                >
                  <multiselect
                    v-model="selectedCurrency"
                    :options="currencies"
                    :internal-search="true"
                    deselect-label=""
                    :allow-empty="false"
                    open-direction="bottom"
                    select-label=""
                    selected-label=""
                    track-by="name"
                    label="name"
                    :placeholder="$t('finance.currency')"
                    name="Currency Type"
                    :class="classes"
                    :disabled="isSubmitting"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                    <template slot="option" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                    <template slot="noOptions">{{ "List is empty" }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group flex-column">
                <label class="col-form-label">{{ $t('finance.bank') }}</label>
                <validation-provider
                  rules="required"
                  :name="$t('finance.bank')"
                  v-slot="{ classes, errors }"
                >
                  <multiselect
                    v-model="selectedBank"
                    :options="banks"
                    :internal-search="true"
                    @input="onBankChange"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label=""
                    select-label=""
                    selected-label=""
                    track-by="name"
                    label="name"
                    :placeholder="$t('finance.bank')"
                    name="Bank"
                    :class="classes"
                    :disabled="isSubmitting"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                    <template slot="option" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                    <template slot="noOptions">{{ "List is empty" }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group flex-column">
                <label class="col-form-label">{{ getIbanTypeLabel }}</label>
                <validation-provider
                  rules="required|iban"
                  :name="getIbanTypeLabel"
                  v-slot="{ classes, errors }"
                >
                  <div class="iban-type">{{ ibanType }}</div>
                  <input
                    class="form-control pl-10"
                    :class="classes"
                    name="IBAN"
                    type="text"
                    v-model="formData.address"
                    :placeholder="getIbanTypeLabel"
                    :disabled="isSubmitting"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group text-center mt-10">
                <form-submit-btn
                  :btnClass="'btn btn-pill btn-primary w-75'"
                  :isSubmitting="isSubmitting"
                  @click="handleSubmit(submitForm)"
                  :text="$t('commons.save')"
                />
              </div>
            </form>
            <form class="form" v-if="provider === 'crypto_currency'">
              <div class="form-group flex-column">
                <label class="col-form-label">{{ $t('finance.currency') }}</label>
                <validation-provider
                  rules="required"
                  :name="$t('finance.currency')"
                  v-slot="{ classes, errors }"
                >
                  <multiselect
                    v-model="selectedCurrency"
                    :options="currencies"
                    :internal-search="true"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label=""
                    select-label=""
                    selected-label=""
                    track-by="name"
                    label="name"
                    :placeholder="$t('finance.currency')"
                    name="Currency Type"
                    :class="classes"
                    :disabled="isSubmitting"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                    <template slot="option" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                    <template slot="noOptions">{{ "List is empty" }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group flex-column">
                <label class="col-form-label">{{ $t('finance.wallet_name') }}</label>
                <validation-provider
                  rules="required"
                  :name="$t('finance.wallet_name')"
                  v-slot="{ classes, errors }"
                >
                  <input
                    class="form-control"
                    :class="classes"
                    type="text"
                    name="wallet-name"
                    v-model="formData.label"
                    :placeholder="$t('finance.wallet_name')"
                    :disabled="isSubmitting"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group flex-column">
                <label class="col-form-label">{{ $t('finance.wallet_address') }}</label>
                <validation-provider
                  rules="required"
                  :name="$t('finance.wallet_address')"
                  v-slot="{ classes, errors }"
                >
                  <input
                    class="form-control"
                    :class="classes"
                    type="text"
                    v-model="formData.address"
                    :placeholder="$t('finance.wallet_address')"
                    name="wallet-address"
                    :disabled="isSubmitting"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group flex-column" v-if="hasWalletTag">
                <label class="col-form-label">{{ $t('finance.wallet_tag') }}</label>
                <validation-provider
                  rules="required"
                  :name="$t('finance.wallet_tag')"
                  v-slot="{ classes, errors }"
                >
                  <input
                    class="form-control"
                    :class="classes"
                    type="text"
                    v-model="formData.tag"
                    name="wallet-tag"
                    :placeholder="$t('finance.wallet_tag')"
                    :disabled="isSubmitting"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group text-center mt-10">
                <form-submit-btn
                  :btnClass="'btn btn-pill btn-primary w-75'"
                  :isSubmitting="isSubmitting"
                  @click="handleSubmit(submitForm)"
                  :text="$t('commons.save')"
                />
              </div>
            </form>
            <!--end::Create Bank Account -->
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "Modal",
  props: {
    provider: {
      type: String,
      default: "crypto_currency",
    },
    crypto: {
      type: Object,
      default: () => {},
    },
    currencies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {
        label: "",
        address: "",
        tag: "",
      },
      selectedCurrency: null,
      selectedBank: null,
      ibanType: "",
      isSubmitting: false,
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapState({
      showCreateWalletModal: (state) => state.global.showCreateWalletModal,
      banksLoading: (state) => state.finance.banksLoading,
      banks: (state) => state.finance.banks,
    }),
    getTitle() {
      if (this.provider === "crypto_currency") return this.$t('finance.add_wallet');
      return this.$t('finance.add_bank_account');
    },
    getIbanTypeLabel() {
      if (!this.ibanType || this.ibanType === "TR") return "IBAN";
      return "UBAN";
    },
    hasWalletTag() {
      return ["XRP", "XLM", "xrp", "xlm"].includes(this.selectedCurrency?.code);
    },
  },
  methods: {
    ...mapActions("finance", ["GET_COMMON_BANKS"]),
    ...mapActions("withdraw", ["CREATE_WALLET"]),
    createCryptoWallet() {
      const data = {
        provider: "crypto_currency",
        currency: this.selectedCurrency?.code?.toLowerCase(),
        label: this.formData.label,
        address: this.formData.address.trim().replace(/ +/g, ""),
      };
      if (this.hasWalletTag) {
        data.tag = this.formData.tag;
      }
      this.isSubmitting = true;
      this.CREATE_WALLET({ formData: data, isCrypto: true })
        .then(() => {
          this.$emit("walletCreated", { currency: data.currency });
          this.closeModal();
        })
        .catch(() => {})
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    createBankAccount() {
      const vals = {
        provider: "bank_transfer",
        currency: this.selectedCurrency?.code?.toLowerCase(),
        address: `${this.ibanType || "TR"}${this.formData.address}`
          .trim()
          .replace(/ +/g, ""),
        bank: this.selectedBank?.id,
        label: this.formData.label,
      };
      this.isSubmitting = true;
      this.CREATE_WALLET({ formData: vals, isCrypto: false })
        .then(() => {
          this.$emit("walletCreated", { currency: vals.currency });
          this.closeModal();
        })
        .catch(() => {})
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    submitForm() {
      if (this.provider === "crypto_currency") {
        this.createCryptoWallet();
        return;
      }
      this.createBankAccount();
    },
    closeModal() {
      this.$store.commit("global/SET_CREATE_WALLET_MODAL", false);
    },
    onBankChange() {
      if (this.selectedBank) {
        let iban = this.banks.find(
          (val) => val.id === this.selectedBank.id
        ).type;
        if (iban) {
          iban === "IBAN" ? (this.ibanType = "TR") : (this.ibanType = "CT");
        }
      }
    },
  },
  created() {},

  watch: {
    showCreateWalletModal: function (newValue, oldValue) {
      if (!oldValue && newValue) {
        this.selectedCurrency = this.crypto;
        this.formData.label = "";
        this.formData.address = "";
        this.provider === "bank_transfer" && this.GET_COMMON_BANKS();
      }
    },
  },
};
</script>

<style scoped>
.show {
  display: block;
}
.iban-type {
  position: absolute;
  height: calc(1.5em + 1.3rem + 2px);
  left: 2.5rem;
  align-items: center;
  display: flex;
}
</style>